// extracted by mini-css-extract-plugin
export var arrowLeft = "ImageCarousel-module--arrowLeft--29b18";
export var arrowRight = "ImageCarousel-module--arrowRight--4380a";
export var controls = "ImageCarousel-module--controls--34fb0";
export var fadeAway = "ImageCarousel-module--fadeAway--1d158";
export var fadeIn = "ImageCarousel-module--fadeIn--accd9";
export var imageCarousel = "ImageCarousel-module--imageCarousel--b650b";
export var imageListFlex = "ImageCarousel-module--imageListFlex--d397e";
export var mainImage = "ImageCarousel-module--mainImage--d20c8";
export var mainImageContainer = "ImageCarousel-module--mainImageContainer--0bee7";
export var selectedImage = "ImageCarousel-module--selectedImage--ec0f9";