import * as React from 'react';
import * as styles from './spacea.module.scss';
import WorkExample from '../../../components/WorkExample';

//Components
import Seo from '../../../components/seo';
import ImageCarousel from '../../../components/ImageCarousel/ImageCarousel';

//Images
import spaceAHeaderImage from '../../../images/work-example-headers/space-a.png';
import userInterviewsImage from "../../../images/spacea/interview.jpg";
import personaImage from "../../../images/spacea/persona.png";
import loginImage1 from "../../../images/spacea/login1.jpg";
import loginImage2 from "../../../images/spacea/login2.jpg";
import loginImage3 from "../../../images/spacea/login3.jpg";
import whiteboardImage from "../../../images/spacea/whiteboard.jpg";
import formImage1 from "../../../images/spacea/form1.png";
import formImage2 from "../../../images/spacea/form2.png";
import formImage3 from "../../../images/spacea/form3.png";
import formImage4 from "../../../images/spacea/form4.png";
import formImage5 from "../../../images/spacea/form5.png";
import flightDetailsImage from "../../../images/spacea/flight-details.jpg";
import terminalDetailsImage from "../../../images/spacea/terminal-details.jpg";
import allTerminalsImage from "../../../images/spacea/all-terminals.jpg";
import requestStatusImage from "../../../images/spacea/request-status.png";

const SpaceA = ({ location }) => {
    return (
        <WorkExample
            pageName={'spacea'}
            cardColor={'#134f77'}
            title={'Space A'}
            subtitle={"Design of a mobile app to help military service members take advantage of an underutilized benefit"}
            imageData={spaceAHeaderImage}
            imageAlt={"Space A"}
            links={[]}
            location={location}
        >

            <Seo
                title={"Space A"}
                description={"Design of a mobile app to help military service members take advantage of an underutilized benefit"}
            />

            <h2>Overview</h2>
            <h3>About the Project</h3>
            <p>Space Available Travel (Space-A for short) is a
                benefit provided to US Service members that allows them to
                take seats on military cargo planes for free as a cheap means
                of travel during their service leave.  During my time at General
                Assembly's user experience design imersive program I worked on
                a design for an app that would help to streamline the antiquated
                process of accessing this benefit.</p>

            <h3>What I did</h3>
            <ul>
                <li>Created wireframes mockups</li>
                <li>Plotted users flows for the form submission process of booking flights</li>
                <li>Conducted user research at a US airforce terminal to learn from the staff and current users of Space-A</li>
            </ul>

            <h3>Goals</h3>
            <ul>
                <li>Create a centralized portal to view flights from all terminals</li>
                <li>Allow users to fill out a request form online </li>
                <li>Provide users with more accurate information on whether they will be able to get onto their chosen flight</li>
            </ul>

            <h3>Challenges</h3>
            <ul>
                <li>There are no guarantees with Space-A since the primary purpose of these flights is to transport cargo. </li>
                <li>When dealing with any military program, security and privacy are extremely important </li>
                <li>Interviewing current users can be difficult since the terminals used are on secure military bases</li>
            </ul>

            <h2>Phase 1: Research the Problem</h2>
            <p>We began this project with the basic understanding that the process for utilizing Space Available Travel was outdated and difficult, but we still needed to find out what parts of the current system were problematic and how potential users could be better served.  In order to accomplish this, we completed two pieces of research:</p>
            <ul>
                <li>Usability tests of the existing process </li>
                <li>Interviews with previous users, potential future users, and the staff who run the program.  </li>
            </ul>

            <h3>Usability testing to the existing process</h3>
            <p>The purpose of this usability testing was to understand if new users were able to understand the process as it was, and if not, how we might be able to solve their pain points.  Essentially, we wanted to validate our problem and to give ourselves a jumping off point for our own work. Testing consisted of 5 participants who were not familiar with Space-A beforehand.  We gave them a basic description of what Space-A was and provided them a link to the website where Space-A is managed, which is called Air Mobility Command.  They were instructed to learn how apply for travel and to find a flight near them. </p>
            <p>Key Insights</p>
            <ul>
                <li>Users find it difficult to scroll through the large amount of text on the site.</li>
                <li>Users find the sign up process very difficult.</li>
                <li>Users find it difficult to find flight schedules.</li>
                <li>User do not trust the Space-A site.</li>
            </ul>
            <h3>User Interviews</h3>
            <div className={styles.imageContainer}>
                <img className={styles.seventyPercentImage} src={userInterviewsImage} alt="user interviews" />
            </div>
            <p>We spoke with 7 people during the course of our interviews: 4 active duty service members at recruiting centers, 1 veteran at the USO, and 2 employees at the passenger terminal at Joint Base McGuire in New Jersey.  All but one of the people we interviewed had used Space-A in the past.  Through these interviews we wanted to find out:</p>
            <ul>
                <li>What people already enjoyed about Space-A </li>
                <li>Problems they faced using the current website</li>
                <li>Issues they faced at the terminals </li>
                <li>Reasons they might not use the service again</li>
                <li>Common website-related problems employees faced at terminals</li>
            </ul>
            <p>Insights from active duty</p>
            <p>We found that all of those we interviewed were quite fond of Space-A and said they would use it again in the future.  Despite this, they had some major concerns about the consistency of the service.  They mentioned that they routinely wasted their time going to terminals to take flights only to find out that they had been cancelled or had filled without warning.  They also mentioned that the process of requesting travel and finding flights was tedious and confusing.</p>
            <p>Insights from veteran</p>
            <p>The veteran told us he used to use Space-A when he was active duty, but now that he's a veteran his status on Space-A listings is lower than it used to be.  Since many active duty soldiers use Space-A to travel home for leave to see family, they're given priority over veterans who typically are using the service for strictly leisure purposes.  </p>
            <p>Insights from passenger terminal representatives</p>
            <ul>
                <li>They receive many calls asking about available flights despite them being listed online. </li>
                <li>They said many who called didn't know the Facebook pages listing flights even existed, and some people who called wanted to verify with them because the Facebook pages didn't seem trustworthy.  </li>
                <li>They can't post flight times/arrivals on facebook because doing so would jeopardize security protocol.</li>
                <li>They receive a lot of calls from people asking if they have a good chance of getting a seat</li>
                <li>They try to shut down the Facebook post as soon as they can if more people sign up for a flight than there are seats</li>
            </ul>
            <h3>Persona</h3>
            <div className={styles.imageContainer}>
                <img className={styles.fullWidthImage} src={personaImage} alt="persona" />
            </div>
            <h2>Phase 2: Develop Solutions</h2>
            <h3>Secure Login</h3>
            <p>Before drawing up any sketches, we wanted to first work out some of the technical aspects of how the app would function. Based on our interviews and testing, we already knew that users wanted more details about flights, but the terminal was not allowed to post the details on Facebook because of security. Additionally, we wanted the process for applying for travel to as simple as possible. The solution we found for these issues was to have users sign in with DS Logon, a secure ID provided by the Department of Defense to all service members. This was a convenient way to verify that all of our users would have a military background, thus eliminating the issue of flight information security.  As an added bonus, DS Logon also stores a variety of data that can be pulled directly into our app which includes the user's:</p>
            <ul>
                <li>Name</li>
                <li>Date of Birth</li>
                <li>Rank</li>
                <li>Military Branch</li>
                <li>Leave Status</li>
                <li>List of Dependents</li>
            </ul>
            <ul className={styles.imageFlexRow}>
                <li><img className={styles.fullWidthImage} src={loginImage1} alt="login1" /></li>
                <li><img className={styles.fullWidthImage} src={loginImage2} alt="login2" /></li>
                <li><img className={styles.fullWidthImage} src={loginImage3} alt="login3" /></li>
            </ul>
            <h3>Easy Document Submission</h3>
            <p>One of the most important pieces of this project was streamlining the process of filling out the "Form 140" used for travel requests. This document is essential for users to be able to use the service, so making it as easy as possible to fill out was quite important.  Since the user's data can be used from DS Logon and Leave Web, the app will be able to autofill some of the fields as well as skip certain sections. Because of this, we decided to create a user flow diagram to map out the possible screens that could appear during the form depending on the user's leave status, military rank, etc.  </p>
            <div className={styles.imageContainer}>
                <img className={styles.seventyPercentImage} src={whiteboardImage} alt="user flows" />
            </div>
            <p>The resulting form we created (shown in the gallery below) pulls information from DS Logon, allowing the user to input only a few details.  The user's name, rank, leave status, and dependents are auto-populated into the form, allowing for an easy submission.  All the user needs to do then is fill out the country of origin, the dependents they will be traveling with, and the relevant passport information.  If the user doesn't plan to leave the country or travel with dependents, the form essentially fills itself out. </p>
            <ImageCarousel 
                images={[
                    formImage1, 
                    formImage2, 
                    formImage3, 
                    formImage4, 
                    formImage5
                ]}
            />
        
            <h3>Browse for and request flights</h3>
            <ul className={styles.imageFlexRow}>
                <li><img className={styles.fullWidthImage} src={flightDetailsImage} alt="flight details" /></li>
                <li><img className={styles.fullWidthImage} src={terminalDetailsImage} alt="terminal details" /></li>
                <li><img className={styles.fullWidthImage} src={allTerminalsImage} alt="all terminals" /></li>
            </ul>
            <p>Our goal in creating a process for searching flights was to mimic the look and feel of apps made for commercial airlines while working under the restrictions of the Space-A process.  Unfortunately, allowing users to search for a specific route to and from cities like a normal airline would be nearly impossible given the low volume of flights and the distance between terminals.  For this reason, it made more sense to allow users to browse flights by terminal rather than by a traditional to-and-from search function.</p>
            <p>Our research showed that users of Space-A are already quite knowledgeable about the terminals in the areas they typically travel, so this system makes sense to them.  Additionally, they often use Space-A for leisure travel and will choose their location based on departures from the closest few terminals, so this system would allow them to easily see what is available nearby.</p>
            <p>In order to get a seat on a Space-A flight, users must request a seat and understand their chances getting aboard based on the standby list.  The nature of this service is such that there are no guarantees and nobody is allowed to book a definitive seat.  This is a restriction in the system that we could do nothing about.  What we could do, however, was provide as much information as possible to users to help them understand their chances.  </p>
            <ul>
                <li>Realtime standby lists</li>
                <li>Number of seats (and whether they are firm or tentative)</li>
                <li>List of similar flights upon requesting a seat on a nearly full flight</li>
                <li>Info about the aircraft (as some are hightly unconvensional cargo plans not suitable for all types of passengers)</li>
            </ul>
            <h3>Information at a glance</h3>
            <div className={styles.imageContainer}>
                <img className={styles.fullWidthImage} src={requestStatusImage} alt="request status" />
            </div>
            <p>After developing the major features of the app, we worked on tying it all together with a home screen.  We wanted this screen to provide only the most important information for users at a glance.  The key elements of this page include:</p>
            <ul>
                <li>Travel request status - Notification of request status with call to action to submit form</li>
                <li>Nearby flights - A list of nearby flights based on the user's location and requested travel destinations</li>
                <li>Standby requests - Users get peace of mind with a list of all flights the user has requested seats on.  The user will be notified with a red indicator if their seat position falls below the expected cutoff</li>
            </ul>
        </WorkExample>
    )
}

export default SpaceA;