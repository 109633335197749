import * as React from 'react';
import * as styles from './ImageCarousel.module.scss';

import { useState } from 'react';


const ImageCarousel = ({ images }) => {
    const [selected, setSelected] = useState(images[0])
    const [mainImageClasses, setMainImageClasses] = useState([styles.mainImage])


    const selectImage = (image) => {
        setSelected(image)
    }

    const nextImage = () => {
        setMainImageClasses([styles.mainImage, styles.fadeAway])
        setTimeout(() => {
            setMainImageClasses([styles.mainImage, styles.fadeIn])
            const currentIndex = images.indexOf(selected)
            if (selected == images[images.length - 1]) {
                setSelected(images[0])
            } else {
                setSelected(images[currentIndex + 1])
            }
        }, 400)
       
    }

    const prevImage = () => {
        setMainImageClasses([styles.mainImage, styles.fadeAway])
        setTimeout(() => {
            setMainImageClasses([styles.mainImage, styles.fadeIn])
            const currentIndex = images.indexOf(selected)
            if (selected == images[0]) {
                setSelected(images[images.length - 1])
            } else {
                setSelected(images[currentIndex - 1])
            }
           
        }, 400)

       
    }

    const imageElements = images.map((image) => {
        const imageClasses = [styles.listImage]
        if (image == selected) {
            imageClasses.push(styles.selectedImage)
        }
        return <img src={image} className={imageClasses.join(' ')} onClick={selectImage.bind(null, image)}></img>
    })

    return (
        <div className={styles.imageCarousel}>
            <div className = {styles.mainImageContainer}>
                <img className={mainImageClasses.join(' ')} src={selected}></img>
            </div>
            <div className={styles.imageListFlex}>
                {imageElements}
            </div>
            <div className={styles.controls}>
                <div onClick={prevImage} className={styles.arrowLeft}> <p>{'<'}</p></div>
                <div onClick={nextImage} className={styles.arrowRight}>{'>'}</div>
            </div>
        </div>
    )
}

export default ImageCarousel